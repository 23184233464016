// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AppList from './components/AppList';
import ChangeList from './components/ChangeList';
import ScheduledChangeList from './components/ScheduledChangeList';
import ActiveChangeList from './components/ActiveChangeList';
import Offboarding from './components/Offboarding';
import Outage from './components/Outage';
import SearchApp from './components/SearchApp';
import SilencingRequest from './components/SilencingRequest';
import { callMsGraph } from './auth/graph';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./auth/authConfig";
import HistoricalSilenceRecord from './components/HistoricalSilenceRecord';
import ActiveSilenceRecord from './components/ActiveSilenceRecord';
import SilenceRequestSchedule from './components/SilenceRequestSchedule';
import SilenceRecordHome from './components/SilenceRecordHome';
import { AuthProvider } from './components/AuthContext';  // Import AuthProvider

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [token, setToken] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const data = await callMsGraph(response.accessToken);
      setGraphData(data);
      setToken(response.accessToken);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [accounts]);

  return (
    <AuthProvider> {/* Wrap the app with AuthProvider */}
      <AuthenticatedTemplate>
        <Router>
          <Navbar graphData={graphData} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/AppList" element={<AppList />} />
            <Route path="/ChangeList" element={<ChangeList />} />
            <Route path="/ScheduledChangeList" element={<ScheduledChangeList />} />
            <Route path="/ActiveChangeList" element={<ActiveChangeList />} />
            <Route path="/Offboarding" element={<Offboarding />} />
            <Route path="/SearchApp" element={<SearchApp />} />
            <Route path="/SilencingRequest" element={<SilencingRequest />} />
            <Route path="/Outage" element={<Outage />} />
            <Route path="/historical-silence-record" element={<HistoricalSilenceRecord />} />
            <Route path="/active-silence-record" element={<ActiveSilenceRecord />} />
            <Route path="/silence-request-schedule" element={<SilenceRequestSchedule />} />
            <Route path="/SilenceRecordHome" element={<SilenceRecordHome />} />
          </Routes>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>Authenticating</p>
        {console.log("ENVS ", process.env)}
      </UnauthenticatedTemplate>
    </AuthProvider>
  );
}

export default App;
