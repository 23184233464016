import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { Typography } from "@anchor/react-components/dist/lib/components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";

const Tabledata = () => {
  const { getToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [searchInputs, setSearchInputs] = useState({
    application_name: ''
  });
  const [filteredData, setFilteredData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const application_list_get_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}onboarded-applications-list`;
      const response = await fetch(application_list_get_api_endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setData(data);
        setFilteredData(data);
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      setFilteredData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchData(token); // Initial fetch on component mount
    }
  }, [getToken]);

  useEffect(() => {
    handleFilterData(); // Filter data whenever search inputs change
  }, [searchInputs, data]);

  const handleFilterData = () => {
    let filtered = data;

    if (searchInputs.application_name) {
      filtered = filtered.filter((item) =>
        item.Application_Name &&
        item.Application_Name.toString().toLowerCase().includes(searchInputs.application_name.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setSearchInputs({
      ...searchInputs,
      [key]: value
    });
  };

  const handleSuggestionClick = (suggestion, key) => {
    setSearchInputs({
      ...searchInputs,
      [key]: suggestion
    });
    setSuggestions([]);
  };

  const columns = [
    {
      accessorKey: "Application_Name",
      header: "Application",
      id: "application_name",
      alignData: "left",
      enableSorting: true,
      filterable: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.Application_Name || ""}</Box>,
      searchComponent: (
        <input
          type="text"
          value={searchInputs.application_name}
          onChange={(e) => handleInputChange(e, 'application_name')}
          placeholder="Search Application"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px', textAlign: 'left' }}
        />
      )
    },
    {
      accessorKey: "Environment",
      alignData: "left",
      header: "Environment",
      id: "environment",
      enableSorting: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.Environment || ""}</Box>,
    },
    {
      accessorKey: "HedwigScopeId",
      alignData: "left",
      header: "Hedwig Scope ID",
      id: "hedwig_scope_id",
      enableSorting: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.HedwigScopeId || ""}</Box>,
    },
    {
      accessorKey: "Server_CI",
      header: "Server CI",
      id: "server_ci",
      alignData: "left",
      enableSorting: false,
      cell: ({ row }) => <Box textAlign="left">{row.original.Server_CI || ""}</Box>,
    },
    {
      accessorKey: "WebHook_URL",
      header: "WebHook URL",
      id: "webhook_url",
      alignData: "left",
      enableSorting: false,
      cell: ({ row }) => (
        <Box textAlign="left">
          <span title={row.original.WebHook_URL} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Link
          </span>
        </Box>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const handleOffboarding = async () => {
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    try {
      if (filteredData.some(item => item.Application_Name === searchInputs.application_name)) {
        const token = getToken();
        const offboarding_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}smartmute-offboarding`;
        const response = await fetch(offboarding_api_endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
          },
          body: JSON.stringify({
            "Application_CI": searchInputs.application_name
          }),
        });
        if (response.ok) {
          setSuccessMessage(`Application ${searchInputs.application_name} is offboarded successfully from SmartMute DB`);
          fetchData(token);
        } else {
          const responseData = await response.json();
          if (responseData && responseData.error === 'Application not found') {
            setErrorMessage(
              <span style={{ color: 'red' }}>
                Error: Application {searchInputs.application_name} not present in SmartMute DB
              </span>
            );
          } else {
            setErrorMessage('Error: ' + response.statusText);
          }
        }
      } else {
        setErrorMessage(
          <span style={{ color: 'red' }}>
            Application {searchInputs.application_name} not present
          </span>
        );
      }
    } catch (error) {
      setErrorMessage('Error: ' + error.message);
    }
    setIsLoading(false);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = Array.isArray(filteredData) ? filteredData.slice(startIndex, startIndex + pageSize) : [];

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div className="p-4">
          <div className="mt-8 flex flex-col items-center">
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
              SmartMute Application Onboarding
            </Typography>
          </div>
          <nav className="space-x-4">
            <button onClick={() => fetchData(getToken())} className="nav-link">
              View Application List
            </button>
            <Link to="/SearchApp" className="nav-link">
              New Application Onboard
            </Link>
            <Link to="/Offboarding" className="nav-link">
              Offboarding
            </Link>
          </nav>
        </div>
      </div>

      <div className="mt-7 flex flex-col items-center" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
        </Typography>
        <div className="flex flex-row mb-4">
          <input
            type="text"
            value={searchInputs.application_name}
            onChange={(e) => handleInputChange(e, 'application_name')}
            placeholder="Search for an application..."
            style={{
              width: '300px',
              padding: '10px',
              fontSize: '1em',
              border: '2px solid black',
              borderRadius: '5px',
              marginRight: '10px'
            }}
          />
          <button
            onClick={handleFilterData}
            style={{
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: '#007BFF',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            Search
          </button>
          <button
            onClick={handleOffboarding}
            style={{
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: '#FF0000',
              color: 'white',
              cursor: 'pointer',
              marginLeft: '10px'
            }}
          >
            Offboard
          </button>
        </div>
        {successMessage && (
          <div style={{ color: 'green', marginBottom: '10px' }}>{successMessage}</div>
        )}
        {errorMessage && (
          <div style={{ marginBottom: '10px' }}>{errorMessage}</div>
        )}
        <div
          id="suggestions"
          style={{
            border: '1px solid #ccc',
            display: suggestions.length > 0 ? 'block' : 'none',
            margin: '10px auto',
            width: '300px',
            textAlign: 'left',
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: 'white'
          }}
        >
          {suggestions.map((app, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(app.Application_Name, 'application_name')}
              style={{ padding: '7px', cursor: 'pointer' }}
            >
              {app.Application_Name}
            </div>
          ))}
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <Table
            columnData={columns}
            defaultData={paginatedData}
            key="Code"
            zebraStripes
            overflowX="auto"
            onPageChange={handlePageChange}
            onPageSizeOptionsChange={handlePageSizeChange}
            pageSizeOptions={[15, 20, 30]}
            pageSize={pageSize}
            currentPage={currentPage}
            totalRecords={filteredData.length}
            showPagination
            style={{ width: '80%', maxWidth: '800px' }} // Reduce the table width
          />
        )}
      </div>
    </>
  );
};

export default Tabledata;
