
import React, { useState, useEffect } from 'react';
import { Typography, Button, Modal, Input, Menu, Select, SelectInput, ReactSelect } from '@anchor/react-components';
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";
import { format, addMinutes, parseISO } from 'date-fns';
import LoadingIndicator from './LoadingIndicator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const About = () => {
  const [silenceRecords, setSilenceRecords] = useState([]);
  const [selectedOption, setSelectedOption] = useState('historical');
  const [dateRangeForCal, setDateRangeForCal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ item: {} });
  const [selectedDuration, setSelectedDuration] = useState(30);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [timee, setTime] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [message, setMessage] = useState(null); // New state for messages
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size to 10
  const [searchInput, setSearchInput] = useState('');
  const [filteredDataByApplication, setFilteredData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsUnique, setSuggestionsUnique] = useState([]);
  const handleTime = (event) => {
    setTime(event.value);
    setSelectedValue(event.value);
    console.log(event.value);
  };
  // Handlers for pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
  };
  const handleInputClick = () => {
    setDatePickerOpen((prev) => !prev);
  };
  const handleSubmit = () => {
    console.log(`Submitted action: ${modalContent.action} on row ${modalContent.rowIndex} with duration ${selectedDuration} minutes`);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(false);
  };
  const handleSearch = () => {
    if (searchInput.trim() === '') {
      setFilteredData(filteredDataByApplication);
    } else {
      const filtered = silenceRecords.filter((item) =>
        item.application_name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredData(filtered);
      setSuggestions([]);
      setCurrentPage(1); // Reset to first page on search
    }
  };
  const handleInputChange = async (event) => {
    const value = event.target.value;
    const filtered = silenceRecords.filter((item) =>
      item.application_name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchInput(value);
    if (value.trim() !== '') {
      setSuggestions(filtered);
      const applicationNames = [...new Set(filtered.map(item => item.application_name))];
      setSuggestionsUnique(applicationNames)
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (appName) => {
    setSearchInput(appName);
    setSuggestions([]);
    handleSearch();
  };
  const filteredDataByAppDate = filteredDataByApplication.filter(record => {
    const recordStartTime = new Date(record.start_time);
    const recordEndTime = new Date(record.end_time);
    // Normalize start and end dates to midnight for comparison
    const normalizedStartDate = new Date(startDate);
    normalizedStartDate.setHours(0, 0, 0, 0);
    const normalizedEndDate = new Date(endDate);
    normalizedEndDate.setHours(23, 59, 59, 999);
    return (
      (startDate ? recordStartTime >= normalizedStartDate : true) &&
      (endDate ? recordEndTime <= normalizedEndDate : true)
    );
  });
  // Assuming filteredRecords is calculated based on currentPage and pageSize
  const filterTableRecord = suggestions.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    fetchData(selectedOption);
  }, [selectedOption]);
  const fetchData = async (option) => {
    try {
      const historical_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}/historical-requests-list`;
      const active_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}/active-requests-list`;
      const scheduled_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}/scheduled-requests-list`;
      let endpoint = '';
      switch (option) {
        case 'historical':
          endpoint = historical_api_endpoint;
          setDateRangeForCal(true);
          break;
        case 'active':
          endpoint = active_api_endpoint; // Active records endpoint
          setDateRangeForCal(false);
          break;
        case 'scheduled':
          endpoint = scheduled_api_endpoint;
          setDateRangeForCal(false);
          break;
        default:
          break;
      }
      const response = await fetch(endpoint, { method: 'GET' });
      const data = await response.json();
      const newData = Array.isArray(data)
        ? data.map(item => {
          const deleteButton = option === 'active' ? (
            <Button variant="filled" fit="small" label="Delete" onClick={() => handleDeleteConfirmation(item)} />
          ) : null;
          const extendIDButton = option === 'active' ? (
            <Button variant="filled" fit="small" label="Extend" onClick={() => handleShowModal(item)} />
          ) : null;
          return { ...item, delete: deleteButton, extendID: extendIDButton };
        })
        : [];

      setSilenceRecords(newData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleOptionChange = (option) => {
    setDateRangeForCal(true)
    setSelectedOption(option);
  };
  const handleDeleteConfirmation = (item) => {
    setRecordToDelete(item);
    setDeleteModalOpen(true);
  };
  const handleDelete = async () => {
    const itemToDelete = recordToDelete;
    console.log(itemToDelete);
    const payload = {
      rule_id: itemToDelete.suppression_id.toString(),
      scope_id: "18451", // Hard coded the scope Id
    };
    console.log(payload);
    //the Delete api 
    try {
      const remove_silence_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}smartmute-remove-silence`;
      const response = await fetch(remove_silence_api_endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        console.log('delete request successful');
        fetchData(selectedOption);
        setMessage('Record deleted successfully'); // Success message
      } else {
        console.error('Error deleting the record');
        setMessage('Failed to delete the record'); // Error message
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to delete the record'); // Error message
    } finally {
      setDeleteModalOpen(false);
      setRecordToDelete(null);
      setTimeout(() => setMessage(null), 10000); // Clear message after 3 seconds
    }
  };
  const handleExtendID = (item) => {
    setCurrentRecord(item);
    setIsModalOpen(true);
  };
  const handleExtendSubmit = async () => {
    setLoading(true);
    const payload = {
      application_ci: modalContent.item.application_name,
      start_time: formatDate(modalContent.item.start_time),
      end_time: formatDate(addMinutesToDate(modalContent.item.end_time, selectedValue)),
      suppression_id: modalContent.item.suppression_id.toString(),
      cmdb_change_no: modalContent.item.cmdb_change_no,
      cmdb_outage_no: modalContent.item.cmdb_outage_no,
      github_workflow: formatDate(modalContent.item.github_workflow)
    };
    console.log('Payload:', JSON.stringify(payload)); // Add this line to log the payload
    try {
      const silenceApiEndpoint = `${process.env.REACT_APP_STARGATE_API_URL}smartmute-extend-silence`;
      const response = await fetch(silenceApiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setShowModal(false);
        fetchData('active');
        console.log('Extend request successful');
        setMessage('Record extended successfully'); // Success message
      } else {
        console.error('Error extending the record:', response.statusText);
        const errorText = await response.text();
        console.error('Response error text:', errorText); // Add this line to log the response error text
        setMessage('Failed to extend the record'); // Error message
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to extend the record'); // Error message
    } finally {
      setLoading(false);
      setIsModalOpen(false);
      setCurrentRecord(null);
      setStartTime('');
      setEndTime('');
      setTimeout(() => setMessage(null), 3000); // Clear message after 3 seconds
    }
  };
  const addMinutesToDate = (date, minutesToAdd) => {
    const currentDatenTime = new Date(date);
    const newDatetime = new Date(currentDatenTime.getTime() + minutesToAdd * 60000); // 60000 milliseconds = 1 minute
    return newDatetime;
  };
  const handleShowModal = (item) => {
    setModalContent({ item });
    setShowModal(true);
  };
  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div className="p-4">
          <div className="flex mb-4 space-x-4">
            <button
              className={`nav-link ${selectedOption === 'historical' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
              onClick={() => handleOptionChange('historical')}
            >
              Historical Silence Records
            </button>
            <button
              className={`nav-link ${selectedOption === 'active' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
              onClick={() => handleOptionChange('active')}
            >
              Active Silence Records
            </button>
            <button
              className={`nav-link ${selectedOption === 'scheduled' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
              onClick={() => handleOptionChange('scheduled')}
            >
              Requests for Silencing Schedule
            </button>
          </div>
        </div>
      </div>

      {dateRangeForCal && (
  <div className="mt-8 flex flex-col items-center">
    <Typography variant="h4" className="font-bold text-xl mb-2 text-center">
      SmartMute Silencing Records
    </Typography>
    <div className="flex flex-row items-center mb-4">
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          placeholder="Search for an application..."
          style={{
            width: '300px',
            padding: '10px',
            fontSize: '1em',
            border: '2px solid black',
            borderRadius: '5px',
            marginRight: '10px',
          }}
        />
        <div
          id="suggestions"
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            border: '1px solid #ccc',
            display: suggestions.length > 0 ? 'block' : 'none',
            width: '300px',
            padding: '10px',
            fontSize: '1em',
            border: '1px solid black',
            borderRadius: '5px',
            marginTop: '5px',
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: 'white',
            zIndex: 1000,
          }}
        >
          {suggestionsUnique.map((name, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(name)}
              style={{ padding: '8px', cursor: 'pointer' }}
            >
              {name}
            </div>
          ))}
        </div>
      </div>
      {dateRangeForCal && (
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="text"
              value={startDate && endDate ? `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}` : ''}
              readOnly
              onClick={handleInputClick}
              className="border border-gray-300 rounded p-2 text-center text-lg cursor-pointer"
              style={{ width: '300px' }}
              placeholder="Select Date Range"
            />
          </div>
          {isDatePickerOpen && (
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setDateRange(update);
                if (update[1]) setDatePickerOpen(false); // Close when end date is selected
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              className="mt-2" // Margin to create space above
            />
          )}
        </div>
      )}
    </div>
  </div>
)}

      {message && (
        <div className="flex justify-end px-4 py-2 bg-green-500 text-white">
          {message}
        </div>
      )}
      <div className="flex justify-center">
        <div className="w-full max-w-7xl">
          <Table
            defaultData={filteredDataByAppDate}
            key="Code"
            zebraStripes
            overflowX="auto"
            onPageChange={handlePageChange}
            onPageSizeOptionsChange={handlePageSizeChange}
            pageSizeOptions={[10, 20, 30]}
            pageSize={pageSize}
            currentPage={currentPage}
            totalRecords={filteredDataByApplication.length}
            showPagination
            style={{ width: '100%' }}
            columnData={[
              {
                accessorKey: "application_name",
                header: "Application",
                id: "application_name",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.application_name || ""}</Box>,
              },
              {
                accessorKey: "start_time",
                header: "Start Time",
                id: "start_time",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{formatDate(row.original.start_time) || ""}</Box>,
              },
              {
                accessorKey: "end_time",
                header: "End Time",
                id: "end_time",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{formatDate(row.original.end_time) || ""}</Box>,
              },
              {
                accessorKey: "cmdb_change_no",
                header: "CMDB Change No",
                id: "cmdb_change_no",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.cmdb_change_no || ""}</Box>,
              },
              {
                accessorKey: "cmdb_outage_no",
                header: "Outage No",
                id: "cmdb_outage_no",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.cmdb_outage_no || ""}</Box>,
              },
              {
                accessorKey: "suppression_id",
                header: "Suppression Id",
                id: "suppression_id",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.suppression_id || ""}</Box>,
              },
              selectedOption === 'active' && {
                accessorKey: "extendID",
                header: "Extend",
                id: "extendID",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.extendID || ""}</Box>,
              },
              selectedOption === 'active' && {
                accessorKey: "delete",
                header: "Unmute",
                id: "delete",
                alignData: "center",
                enableSorting: true,
                cell: ({ row }) => <Box textAlign="center">{row.original.delete || ""}</Box>,
              },
              {
                accessorKey: "github_workflow",
                header: "Github Run",
                id: "github_workflow",
                alignData: "center",
                enableSorting: false,
                cell: ({ row }) => (
                  <Box textAlign="center">
                    {row.original.github_workflow ? (
                      <a
                        href={row.original.github_workflow}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={row.original.github_workflow}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Link
                      </a>
                    ) : (
                      <span>NA</span>
                    )}
                  </Box>
                ),
              },
            ].filter(Boolean)}
          />
        </div>
      </div>
      <Modal aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#0066CC',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Confirm {modalContent.action}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }} />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="inherit" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ ml: 2 }}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        actions={{
          primaryAction: <Button label="Save Changes" onClick={() => handleExtendSubmit(modalContent)} />,
          secondaryAction: <Button label="Cancel" onClick={() => handleCloseModal()} variant="outlined" />,
        }}
        heading="Extension Window"
        showCloseIcon
      >
        {loading ? (
          <LoadingIndicator
            appearance="default"
            fit="medium"
            id="testing"
            label="LOADING..."
            variant="spinner"
          />
        ) : (
          <ReactSelect
            className="react-select"
            closeMenuOnSelect
            customNoOptionsMessage={<p>Not a valid option</p>}
            errorMessage="This is error message"
            fit="medium"
            hintPosition="left"
            id="selectTime"
            isClearable
            label="Select an Extension in mins"
            name="selectTime"
            onChange={handleTime}
            options={[
              { id: '15', label: '15min', value: '15' },
              { id: '30', label: '30min', value: '30' },
              { id: '60', label: '60min', value: '60' },
              { id: '90', label: '90min', value: '90' },
              { id: '120', label: '120min', value: '120' },
            ]}
            orientation="vertical"
            placeholder="Select an extension"
            required
            suggestType="static"
            tooltipDescription="Hello"
            variant="default"
            width={50}
          />
        )}
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        actions={{
          primaryAction: <Button label="Yes" onClick={handleDelete} />,
          secondaryAction: <Button label="No" onClick={() => setDeleteModalOpen(false)} variant="outlined" />,
        }}
        heading="Delete Confirmation"
        showCloseIcon
      >
        <Typography id="delete-modal-title" variant="h2" component="h2">
          Are you sure you want to delete this record?
        </Typography>
      </Modal>
    </>
  );
};
export default About;
