import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import './style.css';
import { Typography } from "@anchor/react-components/dist/lib/components";

function SearchApp() {
    const { getToken } = useContext(AuthContext);
    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedAppDetails, setSelectedAppDetails] = useState([]);
    const [showScope, setShowScope] = useState(false);
    const [scopeId, setScopeId] = useState('');
    const [webhookUrl, setWebhookUrl] = useState('');
    const [serverNames, setServerNames] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) return;

            try {
                const appListUrl = `${process.env.REACT_APP_STARGATE_API_URL}/onboarded-applications-list`;
                const response = await fetch(appListUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [getToken]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        setHasSearched(true);

        if (name === 'searchInput') {
            setSearchInput(value);

            if (value.trim() !== '') {
                try {
                    const token = getToken();
                    const searchUrl = `${process.env.REACT_APP_STARGATE_API_URL}/cmdb-applications-ist`;
                    const payload = { app_name: value };
                    const response = await fetch(searchUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
                        },
                        body: JSON.stringify(payload)
                    });
                    setSelectedAppDetails([]);
                    renderDetailsTable([]);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.length === 0) {
                            setShowScope(false);
                        } else {
                            setShowScope(true);
                        }
                        setSuggestions(data);
                    } else {
                        console.error('Error fetching data:', response.statusText);
                        setSuggestions([]);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setSuggestions([]);
                }
            } else {
                setSuggestions([]);
            }
        } else if (name === 'scopeId') {
            setScopeId(value);
        } else if (name === 'webhookUrl') {
            setWebhookUrl(value);
        }
    };

    const handleSuggestionClick = async (appName) => {
        setSearchInput(appName);
        setSuggestions([]);
        try {
            const token = getToken();
            const appInfoUrl = `${process.env.REACT_APP_STARGATE_API_URL}/cmdb-application-info`;
            const payload = { app_name: appName };
            const response = await fetch(appInfoUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
                    
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const validJsonString = data.replace(/'/g, '"');
                setSelectedAppDetails(JSON.parse(validJsonString));
                handleArray(validJsonString);
            } else {
                console.error('Error fetching data:', response.statusText);
                setSelectedAppDetails([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setSelectedAppDetails([]);
        }
    };

    const handleArray = (data) => {
        let parsedData = data;
        if (typeof data === 'string') {
            try {
                parsedData = JSON.parse(data.replace(/'/g, '"'));
            } catch (error) {
                console.error('Error parsing string data:', error);
                return;
            }
        }

        const serverNamesArray = parsedData.map(item => item.Server_Name);
        setServerNames(serverNamesArray);
    };

    const handleOnboard = async () => {
        setSuccessMessage('');
        setErrorMessage('');

        if (!scopeId.trim() || !webhookUrl.trim()) {
            setErrorMessage('Scope ID and Webhook URL are required.');
            return;
        }

        try {
            const token = getToken();
            const onboardUrl = `${process.env.REACT_APP_STARGATE_API_URL}/onboarding`;
            const payload = {
                Application_CI: searchInput,
                Environment: 'production',
                Server_CI: serverNames.join(', '),
                HedwigScopeID: scopeId,
                webhook_url: webhookUrl,
            };

            const response = await fetch(onboardUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage('Onboarded successfully');
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 1000);
            } else {
                const errorText = await response.text();
                setErrorMessage(`Error during onboarding: ${errorText}`);
                console.error('Error during onboarding:', errorText);
            }
        } catch (error) {
            setErrorMessage(`Error during onboarding: ${error.message}`);
            console.error('Error during onboarding:', error);
        }
    };

    const showAdditionalFunctionalofScope = () => {
        const dataContainsSearchInput = data.some(item => item.Application_Name === searchInput);
        return dataContainsSearchInput;
    };

    const renderDetailsTable = (details) => (
        <div id="details" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {details && Array.isArray(details) && details.length > 0 ? (
                <table style={{ margin: '20px auto', borderCollapse: 'collapse', width: '70%' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px' }}>Application Name</th>
                            <th style={{ border: '1px solid black', padding: '8px' }}>Server Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.map((detail, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{detail.Application_Name}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{detail.Server_Name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>No details available</div>
            )}
        </div>
    );

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error fetching data: {error}</p>;
    }

    return (
        <div>
            {showSuccessMessage && (
                <div style={{ backgroundColor: 'green', color: 'white', textAlign: 'center', padding: '10px', position: 'fixed', top: '50px', width: '100%', zIndex: '999' }}>
                    {successMessage}
                </div>
            )}

            <div className="flex justify-center items-center h-full">
                <div className="p-4">
                    <div className="mt-8 flex flex-col items-center">
                        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
                            SmartMute Application Onboarding
                        </Typography>
                    </div>
                    <nav className="space-x-4">
                        <Link to="/AppList" className="nav-link">
                            View Application List
                        </Link>
                        <Link to="/SearchApp" className="nav-link">
                            New Application Onboard
                        </Link>
                        <Link to="/Offboarding" className="nav-link">
                            Offboarding
                        </Link>
                    </nav>
                </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <b>Search for Application Name:</b>
                <div style={{ marginBottom: '20px' }}>
                    <input
                        type="text"
                        id="search-input"
                        name="searchInput"
                        value={searchInput}
                        onChange={handleInputChange}
                        style={{
                            width: '30%',
                            padding: '10px',
                            fontSize: '1.2em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left'
                        }}
                    />
                </div>
                <div
                    id="suggestions"
                    style={{
                        border: '1px solid #ccc',
                        display: suggestions.length > 0 ? 'block' : 'none',
                        margin: '10px auto',
                        width: '30%',
                        textAlign: 'left'
                    }}
                >
                    {suggestions.map((app, index) => (
                        <div
                            key={index}
                            onClick={() => handleSuggestionClick(app.Application_Name)}
                            style={{ padding: '8px', cursor: 'pointer' }}
                        >
                            {app.Application_Name}
                        </div>
                    ))}
                </div>
                <div id="details" style={{ marginTop: '20px', fontSize: '1.2em' }}>
                    {selectedAppDetails.length > 0 ? (
                        renderDetailsTable(selectedAppDetails)
                    ) : (
                        renderDetailsTable([])
                    )}
                </div>

                {hasSearched && showAdditionalFunctionalofScope() && (
                    <p><b>Application is Already Onboarded.</b></p>
                )}

                {!showAdditionalFunctionalofScope() && showScope && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                        <div style={{ marginRight: '10px' }}>
                            <label htmlFor="scope-id">Scope ID: <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <input
                                type="text"
                                id="scope-id"
                                name="scopeId"
                                value={scopeId}
                                onChange={handleInputChange}
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '1.2em',
                                    border: '2px solid black',
                                    borderRadius: '5px'
                                }}
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <label htmlFor="webhook-url">Webhook URL: <span style={{ color: 'red' }}>*</span></label>
                            <br />
                            <input
                                type="text"
                                id="webhook-url"
                                name="webhookUrl"
                                value={webhookUrl}
                                onChange={handleInputChange}
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '1.2em',
                                    border: '2px solid black',
                                    borderRadius: '5px'
                                }}
                            />
                        </div>
                        <div>
                            <button
                                onClick={handleOnboard}
                                style={{
                                    marginTop: '18px',
                                    padding: '10px 20px',
                                    fontSize: '1.2em',
                                    border: '2px solid black',
                                    borderRadius: '5px',
                                    backgroundColor: '#007BFF',
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}

                <div style={{ marginTop: '20px' }}>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                </div>
            </div>
        </div>
    );
}

export default SearchApp;
