import { SignOutButton } from '../authConfigs/signOutPage';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [dashboardUrl, setDashboardUrl] = useState("");

  const { accounts } = useMsal();
  const [initials, setInitials] = useState('');
  const [name, setName] = useState('avatar');
  const [signout, setSignout] = useState(false);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInitials(
      accounts[0]?.name
        .split(' ')
        .map(word => word.slice(0, 1).toUpperCase())
        .join('').slice(0, 2)
    );
    setName(accounts[0]?.name);
  }, [accounts]);


  const signoutBtnStyles = {
    position: 'absolute',
    right: '0',
  };

  const toggleSignout = () => {
    setSignout(!signout);
  };

  const viewDashboard = () => {
    window.open(dashboardUrl);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const Dashboard = e.Dashboard_Name;
    const Namespace = e.Namespace;
    const Service = e.Service_Name;
    const Language = e.Language_Type;
    const Cluster = e.cluster_name;

    const formData = {
      dashboard: Dashboard,
      namespace: Namespace,
      service: Service,
      cluster: Cluster,
      language: Language,
    };
    console.log(formData);
    console.log(formData.cluster);
  };

  return (
    <>
      <nav className="bg-gray-800 text-white py-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-auto mr-2" />
            <Link to="/" className="sc-dAbbOL dAvIAm typography">
              | SmartMute
            </Link>
          </div>

          <div className="md:hidden">
            <button className="focus:outline-none" onClick={toggleMenu}>
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M18.278 16.864a1 1 0 01-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 01-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 011.414-1.414l4.829 4.828 4.828-4.828a1 1 0 011.414 1.414l-4.828 4.829 4.828 4.828z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 010 2H4a1 1 0 110-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
            </button>
          </div>

          <div className="hidden md:flex justify-between items-center space-x-2 ml-auto py-4">
            <Link to="/" className="hover:text-gray-300 flex items-center">
              Home
            </Link>
            <a
              href="https://maersk-tools.atlassian.net/wiki/spaces/SPE/pages/182907012879/SmartMute"
              target="_blank"
              rel="noreferrer"
              className="hover:text-gray-300 flex items-center px-2"
            >
              Documentation
            </a>
          </div>

          <div onClick={toggleSignout} style={{ cursor: 'pointer' }}>
            {name}
          </div>
          {signout && (
            <div style={signoutBtnStyles}>
              <SignOutButton />
            </div>
          )}
        </div>
      </nav>
      <nav></nav>
    </>
  );
};

export default NavBar;
