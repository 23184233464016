import React from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = ({ appearance, fit, id, label, orientation, variant }) => {
  return (
    <div id={id} className={`loading-indicator ${appearance} ${fit} ${orientation} ${variant}`}>
      <div className="spinner"></div>
      <div className="label">{label}</div>
    </div>
  );
};

LoadingIndicator.propTypes = {
  appearance: PropTypes.string,
  fit: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  orientation: PropTypes.string,
  variant: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  appearance: 'default',
  fit: 'large',
  id: '',
  label: 'Loading...',
  orientation: 'vertical',
  variant: 'bar',
};

export default LoadingIndicator;
