import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@anchor/react-components";
import { redirect } from "react-router-dom";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {

            instance.logoutPopup({
                postLogoutRedirectUri: "/",

            });
        } else if (logoutType === "redirect") {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();

        }
    }
    return (
        <>
            <Button label="Sign out" onClick={() => handleLogout("redirect")}>Sign out</Button>
        </>
    )
}