import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Typography } from "@anchor/react-components/dist/lib/components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../components/AuthContext';

const Tabledata = () => {
  const { getToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size to 10
  const [searchInputs, setSearchInputs] = useState({
    number: '',
    cmdb_ci: '',
    silencing_status: '',
    category: '',
    u_environment: '',
    risk: '',
    short_description: '',
    start_date: [null, null], // Date range [start, end]
    end_date: [null, null], // Date range [start, end]
    state: ''
  });
  const [filteredData, setFilteredData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const token = getToken();
    if (!token) return;
    
    try {
      const application_list_get_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}historical-change-requests-list`;
      const response = await fetch(application_list_get_api_endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
        },
      });
      const data = await response.json();
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(); // Initial fetch on component mount
  }, []);

  useEffect(() => {
    handleFilterData(); // Filter data whenever search inputs change
  }, [searchInputs]);

  const handleFilterData = () => {
    let filtered = data;

    Object.keys(searchInputs).forEach((key) => {
      if (searchInputs[key] && key !== 'start_date' && key !== 'end_date') {
        filtered = filtered.filter((item) =>
          item[key].toString().toLowerCase().includes(searchInputs[key].toLowerCase())
        );
      }
    });

    // Date range filtering
    if (searchInputs.start_date[0] && searchInputs.start_date[1]) {
      const [startRangeStart, startRangeEnd] = searchInputs.start_date;
      filtered = filtered.filter((item) => {
        const itemStartDate = new Date(item.start_date);
        return itemStartDate >= new Date(startRangeStart) && itemStartDate <= new Date(startRangeEnd);
      });
    }

    if (searchInputs.end_date[0] && searchInputs.end_date[1]) {
      const [endRangeStart, endRangeEnd] = searchInputs.end_date;
      filtered = filtered.filter((item) => {
        const itemEndDate = new Date(item.end_date);
        return itemEndDate >= new Date(endRangeStart) && itemEndDate <= new Date(endRangeEnd);
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setSearchInputs({
      ...searchInputs,
      [key]: value
    });
  };

  const handleDateChange = (dates, key) => {
    setSearchInputs({
      ...searchInputs,
      [key]: dates
    });
  };

  const handleSuggestionClick = (number) => {
    setSearchInputs({ number });
    handleFilterData();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const columns = [
    {
      accessorKey: "number",
      header: "Number",
      id: "number",
      enableSorting: true,
      filterable: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.number || ""}>
          {row.original.number || ""}
        </Box>
      ),
      searchComponent: (
        <input
          type="text"
          value={searchInputs.number}
          onChange={(e) => handleInputChange(e, 'number')}
          placeholder="Search Number"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px' }}
        />
      )
    },
    {
      accessorKey: "cmdb_ci",
      header: "CMDB CI",
      id: "cmdb_ci",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.cmdb_ci || ""}>
          {row.original.cmdb_ci || ""}
        </Box>
      ),
      searchComponent: (
        <input
          type="text"
          value={searchInputs.cmdb_ci}
          onChange={(e) => handleInputChange(e, 'cmdb_ci')}
          placeholder="Search CMDB CI"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px' }}
        />
      )
    },
    {
      accessorKey: "silencing_status",
      header: "Silencing Status",
      id: "silencing_status",
      enableSorting: true,
      cell: ({ row }) => (
        <Box
          style={{
            backgroundColor: row.original.silencing_status === 'Yes' ? 'green' : 'yellow',
            color: 'black',
            padding: '5px',
            borderRadius: '3px',
            textAlign: 'left',
          }}
          title={row.original.silencing_status || ""}
        >
          {row.original.silencing_status || ""}
        </Box>
      ),
      searchComponent: (
        <input
          type="text"
          value={searchInputs.silencing_status}
          onChange={(e) => handleInputChange(e, 'silencing_status')}
          placeholder="Search Silencing Status"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px' }}
        />
      )
    },
    {
      accessorKey: "category",
      header: "Category",
      id: "category",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.category || ""}>
          {row.original.category || ""}
        </Box>
      ),
      
    },
    {
      accessorKey: "u_environment",
      header: "Environment",
      id: "u_environment",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.u_environment || ""}>
          {row.original.u_environment || ""}
        </Box>
      ),
     
    },
    {
      accessorKey: "risk",
      header: "Risk",
      id: "risk",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.risk || ""}>
          {row.original.risk || ""}
        </Box>
      ),
      
    },
    {
      accessorKey: "short_description",
      header: "Short Description",
      id: "short_description",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.short_description || ""}>
          {row.original.short_description || ""}
        </Box>
      ),
      searchComponent: (
        <input
          type="text"
          value={searchInputs.short_description}
          onChange={(e) => handleInputChange(e, 'short_description')}
          placeholder="Search Short Description"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px' }}
        />
      )
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      id: "start_date",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.start_date || ""}>
          {row.original.start_date || ""}
        </Box>
      ),
      searchComponent: (
        <DatePicker
          selected={searchInputs.start_date[0]}
          onChange={(dates) => handleDateChange(dates, 'start_date')}
          startDate={searchInputs.start_date[0]}
          endDate={searchInputs.start_date[1]}
          selectsRange
          isClearable
          placeholderText="Select Start Date Range"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px' }}
        />
      )
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      id: "end_date",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.end_date || ""}>
          {row.original.end_date || ""}
        </Box>
      ),
 
    },
    {
      accessorKey: "state",
      header: "State",
      id: "state",
      enableSorting: true,
      cell: ({ row }) => (
        <Box style={{ textAlign: 'left' }} title={row.original.state || ""}>
          {row.original.state || ""}
        </Box>
      ),
      
    },
  ];

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <>
    <div className="flex justify-center items-center h-full">
        <div className="p-4">
          <div className="mt-8 flex flex-col items-center">
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
            Change Request Dashboard
            </Typography>
          </div>
          <nav className="space-x-4">
            <Link to="/ChangeList" className="nav-link">
              Historical Change Request
            </Link>
            <Link to="/ActiveChangeList" className="nav-link">
              Active Change Request
            </Link>
            <Link to="/ScheduledChangeList" className="nav-link">
              Scheduled Change Request
            </Link>
          </nav>
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center" style={{ width: '90%', margin: '0 auto' }}>
        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
          
        </Typography>
        <div className="flex flex-row mb-4">
          {columns.map((column) => (
            <div key={column.id} style={{ marginRight: '10px' }}>
              {column.searchComponent}
            </div>
          ))}
          <button
            onClick={handleFilterData}
            style={{
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: '#007BFF',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            Search
          </button>
        </div>
        <div
          id="suggestions"
          style={{
            border: '1px solid #ccc',
            display: suggestions.length > 0 ? 'block' : 'none',
            margin: '10px auto',
            width: '300px',
            textAlign: 'left',
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: 'white',
          }}
        >
          {suggestions.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(item.number)}
              style={{ padding: '8px', cursor: 'pointer' }}
            >
              {item.number}
            </div>
          ))}
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <Table
            columnData={columns}
            defaultData={paginatedData}
            key="Code"
            zebraStripes
            overflowX="auto"
            onPageChange={handlePageChange}
            onPageSizeOptionsChange={handlePageSizeChange}
            pageSizeOptions={[10, 20, 30]}
            pageSize={pageSize}
            currentPage={currentPage}
            totalRecords={filteredData.length}
            showPagination
            style={{ width: '100%' }} // Make the table wider
          />
        )}
      </div>
    </>
  );
};

export default Tabledata;
