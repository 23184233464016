// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState(localStorage.getItem('tokenExpiration') || '');

    useEffect(() => {
        if (accessToken && tokenExpiration && new Date(tokenExpiration) > new Date()) {
            // Token is valid
        } else {
            fetchAccessToken();
        }
    }, []);

    const fetchAccessToken = async () => {
        try {
            const tokenResponse = await fetch(process.env.REACT_APP_ACCESS_TOKEN_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Consumer-Key': process.env.REACT_APP_CLIENT_ID
                },
                body: new URLSearchParams({
                    grant_type: 'client_credentials',
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CLIENT_SECRET,
                }),
            });

            const tokenData = await tokenResponse.json();
            if (tokenResponse.ok) {
                const token = tokenData.access_token;
                const expiresIn = tokenData.expires_in; // assuming expires_in is in seconds
                const expirationTime = new Date(new Date().getTime() + expiresIn * 7199);

                setAccessToken(token);
                setTokenExpiration(expirationTime.toISOString());
                localStorage.setItem('accessToken', token);
                localStorage.setItem('tokenExpiration', expirationTime.toISOString());
                console.log('Access Token:', token);
            } else {
                console.error('Error fetching access token:', tokenData);
            }
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    const getToken = () => {
        if (new Date(tokenExpiration) <= new Date()) {
            fetchAccessToken();
        }
        return accessToken;
    };

    return (
        <AuthContext.Provider value={{ getToken, fetchAccessToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };