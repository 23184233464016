import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */



export const msalConfig = {

    auth: {
      
        clientId : process.env.REACT_APP_APP_REGISTRATION,  // sre-pe-skydeck app registration Application/client ID
       
        authority: "https://login.microsoftonline.com/"+process.env.REACT_APP_AZURE_AD_TENANT,
        //redirectUri:"http://localhost:3000"
        redirectUri: process.env.REACT_APP_REDIRECT_URL
       
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        //cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};


export const loginRequest = {
    scopes: ["user.Read","chat.Create","chat.ReadWrite", "People.Read", "People.Read.All"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me" // this did NOT give CORS error
};