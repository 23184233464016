import React, { useState, useEffect, useContext } from 'react';
import NavButton from '../components/NavButton';
import '../App.css';
import { Typography, Button, Modal, ReactSelect } from '@anchor/react-components';
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";
import { format, addMinutes } from 'date-fns';
import LoadingIndicator from './LoadingIndicator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../components/AuthContext';

const HistoricalSilenceRecord = () => {
    const { getToken } = useContext(AuthContext);
    const [silenceRecords, setSilenceRecords] = useState([]);
    const [selectedOption, setSelectedOption] = useState('historical');
    const [dateRangeForCal, setDateRangeForCal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ item: {} });
    const [selectedDuration, setSelectedDuration] = useState(30);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [time, setTime] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [message, setMessage] = useState(null); // New state for messages
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Default page size to 10
    const [searchInputs, setSearchInputs] = useState({
        application_name: '',
        cmdb_change_no: '',
        suppression_id: ''
    });
    const [filteredData, setFilteredData] = useState([]);

    const handleTime = (event) => {
        setTime(event.value);
        setSelectedValue(event.value);
    };

    // Handlers for pagination
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
        setCurrentPage(1); // Reset to the first page when page size changes
    };

    const handleSubmit = () => {
        console.log(`Submitted action: ${modalContent.action} on row ${modalContent.rowIndex} with duration ${selectedDuration} minutes`);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(false);
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        fetchData(selectedOption);
    }, [selectedOption]);

    useEffect(() => {
        handleFilterData(); // Filter data whenever search inputs or date range change
    }, [searchInputs, dateRange, silenceRecords]);

    const fetchData = async (option) => {
        setLoading(true);
        const token = getToken();
        if (!token) return;

        try {
            const historical_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}/historical-requests-list`;
            let endpoint = '';
            switch (option) {
                case 'historical':
                    endpoint = historical_api_endpoint;
                    setDateRangeForCal(true);
                    break;
                default:
                    break;
            }

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
                },
            });
            const data = await response.json();
            const newData = Array.isArray(data)
                ? data.map(item => {
                    const deleteButton = option === 'active' ? (
                        <Button variant="filled" fit="small" label="Delete" onClick={() => handleDeleteConfirmation(item)} />
                    ) : null;
                    const extendIDButton = option === 'active' ? (
                        <Button variant="filled" fit="small" label="Extend" onClick={() => handleShowModal(item)} />
                    ) : null;
                    return { ...item, delete: deleteButton, extendID: extendIDButton };
                })
                : [];
            setSilenceRecords(newData);
            setFilteredData(newData); // Initialize filteredData with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterData = () => {
        let filtered = silenceRecords;

        if (searchInputs.application_name) {
            filtered = filtered.filter((item) =>
                item.application_name &&
                item.application_name.toString().toLowerCase().includes(searchInputs.application_name.toLowerCase())
            );
        }
        if (searchInputs.cmdb_change_no) {
            filtered = filtered.filter((item) =>
                item.cmdb_change_no &&
                item.cmdb_change_no.toString().toLowerCase().includes(searchInputs.cmdb_change_no.toLowerCase())
            );
        }
        if (searchInputs.suppression_id) {
            filtered = filtered.filter((item) =>
                item.suppression_id &&
                item.suppression_id.toString().toLowerCase().includes(searchInputs.suppression_id.toLowerCase())
            );
        }

        if (startDate && endDate) {
            filtered = filtered.filter((item) => {
                const itemDate = new Date(item.start_time);
                return itemDate >= startDate && itemDate <= endDate;
            });
        }

        setFilteredData(filtered);
    };

    const handleInputChange = (event, key) => {
        const value = event.target.value;
        setSearchInputs({
            ...searchInputs,
            [key]: value
        });
    };

    const handleOptionChange = (option) => {
        setDateRangeForCal(true);
        setSelectedOption(option);
    };

    const handleDeleteConfirmation = (item) => {
        setRecordToDelete(item);
        setDeleteModalOpen(true);
    };

    const handleDelete = async () => {
        const itemToDelete = recordToDelete;
        console.log(itemToDelete);

        const payload = {
            rule_id: itemToDelete.suppression_id.toString(),
            scope_id: "18451", // Hard coded the scope Id
        };
        console.log(payload);
        //the Delete api 
        try {
            const token = getToken();
            if (!token) return;

            const remove_silence_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}smartmute-remove-silence`;
            const response = await fetch(remove_silence_api_endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            if (response.ok) {
                console.log('delete request successful');
                fetchData(selectedOption);
                setMessage('Record deleted successfully'); // Success message
            } else {
                console.error('Error deleting the record');
                setMessage('Failed to delete the record'); // Error message
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to delete the record'); // Error message
        } finally {
            setDeleteModalOpen(false);
            setRecordToDelete(null);
            setTimeout(() => setMessage(null), 10000); // Clear message after 10 seconds
        }
    };

    const handleExtendID = (item) => {
        setCurrentRecord(item);
        setIsModalOpen(true);
    };

    const handleExtendSubmit = async () => {
        setLoading(true);
        const payload = {
            application_ci: modalContent.item.application_name,
            start_time: formatDate(modalContent.item.start_time),
            end_time: formatDate(addMinutesToDate(modalContent.item.end_time, selectedValue)),
            suppression_id: modalContent.item.suppression_id.toString(),
            cmdb_change_no: modalContent.item.cmdb_change_no,
            cmdb_outage_no: modalContent.item.cmdb_outage_no,
            github_workflow: formatDate(modalContent.item.github_workflow)
        };
        console.log('Payload:', JSON.stringify(payload)); // Add this line to log the payload
        try {
            const token = getToken();
            if (!token) return;

            const silenceApiEndpoint = `${process.env.REACT_APP_STARGATE_API_URL}smartmute-extend-silence`;
            const response = await fetch(silenceApiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            if (response.ok) {
                setShowModal(false);
                fetchData('active');
                console.log('Extend request successful');
                setMessage('Record extended successfully'); // Success message
            } else {
                console.error('Error extending the record:', response.statusText);
                const errorText = await response.text();
                console.error('Response error text:', errorText); // Add this line to log the response error text
                setMessage('Failed to extend the record'); // Error message
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to extend the record'); // Error message
        } finally {
            setLoading(false);
            setIsModalOpen(false);
            setCurrentRecord(null);
            setStartTime('');
            setEndTime('');
            setTimeout(() => setMessage(null), 3000); // Clear message after 3 seconds
        }
    };

    const addMinutesToDate = (date, minutesToAdd) => {
        const currentDatenTime = new Date(date);
        const newDatetime = new Date(currentDatenTime.getTime() + minutesToAdd * 60000); // 60000 milliseconds = 1 minute
        return newDatetime;
    };

    const handleShowModal = (item) => {
        setModalContent({ item });
        setShowModal(true);
    };

    return (
        <>
            <div className="flex justify-center items-center h-full">
                <div className="p-4">
                    <div className="mt-8 flex flex-col items-center">
                        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}> Silencing Records</Typography>
                    </div>
                    <div className="flex mb-4 space-x-4">
                        <NavButton path="/historical-silence-record" label="Historical Silence Records" selected={true} />
                        <NavButton path="/active-silence-record" label="Active Silence Records" selected={false} />
                        <NavButton path="/silence-request-schedule" label="Requests for Silencing Schedule" selected={false} />
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center mb-4">
                <div className="flex mb-4 space-x-4">
                    <input
                        type="text"
                        value={searchInputs.application_name}
                        onChange={(e) => handleInputChange(e, 'application_name')}
                        placeholder="Search Application"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <input
                        type="text"
                        value={searchInputs.cmdb_change_no}
                        onChange={(e) => handleInputChange(e, 'cmdb_change_no')}
                        placeholder="Search CMDB Change No"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <input
                        type="text"
                        value={searchInputs.suppression_id}
                        onChange={(e) => handleInputChange(e, 'suppression_id')}
                        placeholder="Search Suppression Id"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <div className="relative">
                        <input
                            type="text"
                            value={startDate && endDate ? `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}` : ''}
                            readOnly
                            onClick={() => setDatePickerOpen(!isDatePickerOpen)}
                            className="border border-gray-300 rounded p-2 text-center text-lg cursor-pointer"
                            style={{ width: '300px', textAlign: 'left' }} // Left align
                            placeholder="Select Date Range"
                        />
                        {isDatePickerOpen && (
                            <div className="absolute z-10 mt-2">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                        if (update[1]) setDatePickerOpen(false); // Close when end date is selected
                                    }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                    className="mt-2" // Margin to create space above
                                />
                            </div>
                        )}
                    </div>
                    <button
                        onClick={handleFilterData}
                        style={{
                            padding: '10px 20px',
                            fontSize: '1em',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#007BFF',
                            color: 'white',
                            cursor: 'pointer',
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>

            {message && (
                <div className="flex justify-end px-4 py-2 bg-green-500 text-white">
                    {message}
                </div>
            )}

            <div className="flex justify-center">
                <div className="w-full max-w-7xl">
                    <Table
                        defaultData={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                        key="Code"
                        zebraStripes
                        overflowX="auto"
                        onPageChange={handlePageChange}
                        onPageSizeOptionsChange={handlePageSizeChange}
                        pageSizeOptions={[10, 20, 30]}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        totalRecords={filteredData.length}
                        showPagination
                        style={{ width: '100%' }}
                        columnData={[
                            {
                                accessorKey: "application_name",
                                header: "Application",
                                id: "application_name",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.application_name || ""}</Box>,
                            },
                            {
                                accessorKey: "start_time",
                                header: "Start Time",
                                id: "start_time",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{formatDate(row.original.start_time) || ""}</Box>,
                            },
                            {
                                accessorKey: "end_time",
                                header: "End Time",
                                id: "end_time",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{formatDate(row.original.end_time) || ""}</Box>,
                            },
                            {
                                accessorKey: "cmdb_change_no",
                                header: "CMDB Change No",
                                id: "cmdb_change_no",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.cmdb_change_no || ""}</Box>,
                            },
                            {
                                accessorKey: "cmdb_outage_no",
                                header: "Outage No",
                                id: "cmdb_outage_no",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.cmdb_outage_no || ""}</Box>,
                            },
                            {
                                accessorKey: "suppression_id",
                                header: "Suppression Id",
                                id: "suppression_id",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.suppression_id || ""}</Box>,
                            },
                            selectedOption === 'active' && {
                                accessorKey: "extendID",
                                header: "Extend",
                                id: "extendID",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.extendID || ""}</Box>,
                            },
                            selectedOption === 'active' && {
                                accessorKey: "delete",
                                header: "Unmute",
                                id: "delete",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.delete || ""}</Box>,
                            },
                            {
                                accessorKey: "github_workflow",
                                header: "Github Run",
                                id: "github_workflow",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => (
                                    <Box textAlign="left">
                                        {row.original.github_workflow ? (
                                            <a
                                                href={row.original.github_workflow}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={row.original.github_workflow}
                                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            >
                                                Link
                                            </a>
                                        ) : (
                                            <span>NA</span>
                                        )}
                                    </Box>
                                )
                            },
                        ].filter(Boolean)}
                    />
                </div>
            </div>
            <Modal aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#0066CC',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Confirm {modalContent.action}
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }} />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="inherit" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ ml: 2 }}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                actions={{
                    primaryAction: <Button label="Save Changes" onClick={() => handleExtendSubmit(modalContent)} />,
                    secondaryAction: <Button label="Cancel" onClick={() => handleCloseModal()} variant="outlined" />,
                }}
                heading="Extension Window"
                showCloseIcon
            >
                {loading ? (
                    <LoadingIndicator
                        appearance="default"
                        fit="medium"
                        id="testing"
                        label="LOADING..."
                        variant="spinner"
                    />
                ) : (
                    <ReactSelect
                        className="react-select"
                        closeMenuOnSelect
                        customNoOptionsMessage={<p>Not a valid option</p>}
                        errorMessage="This is error message"
                        fit="medium"
                        hintPosition="left"
                        id="selectTime"
                        isClearable
                        label="Select an Extension in mins"
                        name="selectTime"
                        onChange={handleTime}
                        options={[
                            { id: '15', label: '15min', value: '15' },
                            { id: '30', label: '30min', value: '30' },
                            { id: '60', label: '60min', value: '60' },
                            { id: '90', label: '90min', value: '90' },
                            { id: '120', label: '120min', value: '120' },
                        ]}
                        orientation="vertical"
                        placeholder="Select an extension"
                        required
                        suggestType="static"
                        tooltipDescription="Hello"
                        variant="default"
                        width={50}
                    />
                )}
            </Modal>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={{
                    primaryAction: <Button label="Yes" onClick={handleDelete} />,
                    secondaryAction: <Button label="No" onClick={() => setDeleteModalOpen(false)} variant="outlined" />,
                }}
                heading="Delete Confirmation"
                showCloseIcon
            >
                <Typography id="delete-modal-title" variant="h2" component="h2">
                    Are you sure you want to delete this record?
                </Typography>
            </Modal>
        </>
    );
};

export default HistoricalSilenceRecord;
