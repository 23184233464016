import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { Typography } from "@anchor/react-components/dist/lib/components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";

const Tabledata = () => {
  const { getToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size to 10
  const [searchInputs, setSearchInputs] = useState({
    application_name: '',
    hedwig_scope_id: '',
    server_ci: ''
  });
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const application_list_get_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}onboarded-applications-list`;
      const response = await fetch(application_list_get_api_endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,

        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setData(data);
        setFilteredData(data);
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      setFilteredData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchData(token); // Initial fetch on component mount
    }
  }, [getToken]);

  useEffect(() => {
    handleFilterData(); // Filter data whenever search inputs change
  }, [searchInputs, data]);

  const handleFilterData = () => {
    let filtered = data;

    if (searchInputs.application_name) {
      filtered = filtered.filter((item) =>
        item.Application_Name &&
        item.Application_Name.toString().toLowerCase().includes(searchInputs.application_name.toLowerCase())
      );
    }
    if (searchInputs.hedwig_scope_id) {
      filtered = filtered.filter((item) =>
        item.HedwigScopeId &&
        item.HedwigScopeId.toString().toLowerCase().includes(searchInputs.hedwig_scope_id.toLowerCase())
      );
    }
    if (searchInputs.server_ci) {
      filtered = filtered.filter((item) =>
        item.Server_CI &&
        item.Server_CI.toString().toLowerCase().includes(searchInputs.server_ci.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setSearchInputs({
      ...searchInputs,
      [key]: value
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const columns = [
    {
      accessorKey: "Application_Name",
      header: "Application",
      id: "application_name",
      alignData: "left",
      enableSorting: true,
      filterable: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.Application_Name || ""}</Box>,
      searchComponent: (
        <input
          type="text"
          value={searchInputs.application_name}
          onChange={(e) => handleInputChange(e, 'application_name')}
          placeholder="Search Application"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px', textAlign: 'left' }}
        />
      )
    },
    {
      accessorKey: "Environment",
      alignData: "left",
      header: "Environment",
      id: "environment",
      enableSorting: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.Environment || ""}</Box>,
    },
    {
      accessorKey: "HedwigScopeId",
      alignData: "left",
      header: "Hedwig Scope ID",
      id: "hedwig_scope_id",
      enableSorting: true,
      cell: ({ row }) => <Box textAlign="left">{row.original.HedwigScopeId || ""}</Box>,
      searchComponent: (
        <input
          type="text"
          value={searchInputs.hedwig_scope_id}
          onChange={(e) => handleInputChange(e, 'hedwig_scope_id')}
          placeholder="Search Hedwig Scope ID"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px', textAlign: 'left' }}
        />
      )
    },
    {
      accessorKey: "Server_CI",
      header: "Server CI",
      id: "server_ci",
      alignData: "left",
      enableSorting: false,
      cell: ({ row }) => <Box textAlign="left">{row.original.Server_CI || ""}</Box>,
      searchComponent: (
        <input
          type="text"
          value={searchInputs.server_ci}
          onChange={(e) => handleInputChange(e, 'server_ci')}
          placeholder="Search Server CI"
          style={{ width: '100%', padding: '5px', border: '1px solid #ddd', borderRadius: '3px', textAlign: 'left' }}
        />
      )
    },
    {
      accessorKey: "WebHook_URL",
      header: "WebHook URL",
      id: "webhook_url",
      alignData: "left",
      enableSorting: false,
      cell: ({ row }) => (
        <Box textAlign="left">
          <span title={row.original.WebHook_URL} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Link
          </span>
        </Box>
      ),
    },
  ];

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = Array.isArray(filteredData) ? filteredData.slice(startIndex, startIndex + pageSize) : [];

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div className="p-4">
          <div className="mt-8 flex flex-col items-center">
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
              SmartMute Application Onboarding
            </Typography>
          </div>
          <div className="flex mb-4 space-x-4">
            <button onClick={() => fetchData(getToken())} className="nav-link">
              View Application List
            </button>
            <Link to="/SearchApp" className="nav-link">
              New Application Onboard
            </Link>
            <Link to="/Offboarding" className="nav-link">
              Offboarding
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center" style={{ width: '90%', margin: '0 auto' }}>
        <div className="flex flex-row mb-4">
          {columns.map((column) => (
            <div key={column.id} style={{ marginRight: '10px' }}>
              {column.searchComponent}
            </div>
          ))}
          <button
            onClick={handleFilterData}
            style={{
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: '#007BFF',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            Search
          </button>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <Table
            columnData={columns}
            defaultData={paginatedData}
            key="Code"
            zebraStripes
            overflowX="auto"
            onPageChange={handlePageChange}
            onPageSizeOptionsChange={handlePageSizeChange}
            pageSizeOptions={[10, 20, 30]}
            pageSize={pageSize}
            currentPage={currentPage}
            totalRecords={Array.isArray(filteredData) ? filteredData.length : 0}
            showPagination
            style={{ width: '100%' }} // Make the table wider
          />
        )}
      </div>
    </>
  );
};

export default Tabledata;
