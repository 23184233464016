import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import { AuthContext } from '../components/AuthContext';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const OutageAnalytics = () => {
  const { getToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [chartData, setChartData] = useState(null);

  // Fetch outage data when the component mounts
  useEffect(() => {
    fetchOutageData();
  }, []);

  // Update chart data whenever selectedApp or data changes
  useEffect(() => {
    if (selectedApp) {
      updateChartData(selectedApp);
    }
  }, [selectedApp, data]);

  // Function to fetch outage data from API
  const fetchOutageData = async () => {
    const token = getToken();
    if (!token) return;

    try {
      const api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}monthly-outage-app`;
      const response = await fetch(api_endpoint, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY,
        },
      });
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Function to update chart data based on selected application
  const updateChartData = (appName) => {
    const filteredData = data.filter(
      (item) => item.Application_Name === appName
    );

    // Sort filteredData by month
    filteredData.sort((a, b) => new Date(a.Month) - new Date(b.Month));

    const labels = filteredData.map((item) =>
      new Date(item.Month).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
      })
    );
    const outages = filteredData.map((item) => item.Total_Outage);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Total Outage (in minutes)',
          data: outages,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  // Handler for application selection change
  const handleAppChange = (selectedOption) => {
    setSelectedApp(selectedOption.value);
  };

  // Options for the Select component
  const appOptions = [
    ...new Set(data.map((item) => item.Application_Name)),
  ].map((appName) => ({
    value: appName,
    label: appName,
  }));

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '2em' }}>
        Outage Analytics
      </h1>
      <p style={{ textAlign: 'center' }}>
        Please select an application to view the outage data.
      </p>
      <Select
        options={appOptions}
        onChange={handleAppChange}
        placeholder="Select Application"
        styles={{
          container: (provided) => ({
            ...provided,
            margin: '20px auto',
            maxWidth: '400px',
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
      />
      {chartData ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
            marginTop: '40px',
            padding: '20px',
          }}
        >
          <div style={{ width: '50%' }}>
            <Bar
              data={chartData}
              options={{
                indexAxis: 'x', // Ensure bar chart orientation is horizontal
                plugins: {
                  legend: {
                    display: true,
                    position: 'top',
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      label: function (tooltipItem) {
                        return (
                          tooltipItem.dataset.label +
                          ': ' +
                          tooltipItem.formattedValue +
                          ' minutes'
                        );
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Total Outage (in minutes)',
                      font: {
                        weight: 'bold',
                      },
                    },
                  },
                  y: {
                    stacked: true,
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>
      ) : (
        selectedApp && (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            No data available for the selected application.
          </p>
        )
      )}
    </div>
  );
};

export default OutageAnalytics;
