import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
            <div>
                <div className="flex flex-col items-center justify-center px-8 pt-4">
                    <div className="text-center">
                        <div className="sc-AHTeh jtfrWv GenerateCiamReport__header sc-dAbbOL dAvIAm typography" style={{ fontWeight: 'bold', fontSize: '2rem' }}>
                            Welcome to SmartMute System
                        </div>
                        <p className="mb-8" style={{ fontSize: '1.2rem' }}>
                        Automated Solution for Intelligent Alert Suppression in Hedwig System During Scheduled Changes and CI/CD Pipelines
                        </p>
                    </div>
                    <nav className="space-x-4">
            
                        <Link to="/SilenceRecordHome" className="nav-link">
                            Silencing Report
                        </Link>
                        <Link to="/AppList" className="nav-link">
                            Application Onboarding
                        </Link>
                        <Link to="/ChangeList" className="nav-link">
                            Change Report
                        </Link>
                        <Link to="/Outage" className="nav-link">
                            Outage Report
                        </Link>
                        {/* Add your navigation links here if needed */}
                    </nav>
                </div>
            </div>
            {/* Other content of the page */}
            {/* Place other content here */}
        </div>
    );
};

export default Home;
