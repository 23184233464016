// src/components/NavButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavButton = ({ path, label, selected }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(path);
  };

  return (
    <button
      className={`nav-link ${selected ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

export default NavButton;
